import React from 'react';
import { useBrandingStore } from '../state/store';
import PoweredBy from './PoweredBy';
import { useIsEmbedded } from '../lib/hooks';

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  const isEmbedded = useIsEmbedded();
  const { logo, has_poweredby } = useBrandingStore();

  // If our brand logo is shown in header, we do not show 'Powered by'.
  // In embedded mode, we do not show the logo in header, so show 'Powered by'.
  // When a client has a custom logo, we do not show the logo, so show 'Powered by'.
  // However, 'Powered by' can be disabled from the dashboard.
  const showPoweredBy = (isEmbedded || logo) && has_poweredby;
  return <footer className="my-4">{showPoweredBy ? <PoweredBy /> : null}</footer>;
};

export default Footer;
