import motrainLogoSrc from '../assets/motrain.svg';
import incentliLogoSrc from '../assets/incentli.svg';

type Flavour = 'incentli' | 'motrain';

export const flavour: Flavour = (process.env.REACT_APP_FLAVOUR as Flavour | undefined) || 'incentli';

let logoSrc: string;
if (flavour === 'incentli') {
  logoSrc = incentliLogoSrc;
} else {
  logoSrc = motrainLogoSrc;
}

let brandName: string;
if (flavour === 'incentli') {
  brandName = 'Incentli';
} else {
  brandName = 'Motrain';
}

let brandUrl: string;
if (flavour === 'incentli') {
  brandUrl = 'https://incentli.com';
} else {
  brandUrl = 'https://motrain.com';
}

export { brandName, brandUrl, logoSrc };
